import { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import { NextSeo } from "next-seo";
import Script from "next/script";
import dynamic from "next/dynamic";
// import TaboolaAdContainer from "./adContainer/taboolaContainer";
import GoogleAdContainer from "./adContainer/googleAdContainer";
import { useWindowSize } from "api/commonConstants";
import MainContainer from "./mainContainer";
import breadcrumbSchema from "../../constants/websiteSchema/breadcrumb.json";
// import ListingPage from "components/liveblog/listingPage";
// import { baseUrl } from "api/constants";
// import ElectionsWidget from "components/elections-2023/ElectionsWidget";
// import AuthorColumn from "./authorColumn";
const TaboolaAdContainer = dynamic(() => import("./adContainer/taboolaContainer") , {ssr : false});
const BlogWidget = dynamic(() => import("components/liveblog/blogWidget") , {ssr : false});
import { getLanguage } from "localization/i8intl";
// import FAQComponent from "components/faq";
import { MasterConfigContext } from "constants/MasterConfigContext";
import { Container } from "react-bootstrap";

// import Sponsor from "components/Sponsor";
// import getConfig from "next/config";
// import CricketSlider from "./cricketSlider";
// import LiveShow from "./liveShow/liveShow";
// import {
//   fetchAndActivate,
//   getRemoteConfig,
//   getString,
// } from "firebase/remote-config";
// import app from "utils/firebase";
import {
  apstagBidCallback,
  // createApsSlotsRosMobile,
  // createApsSlotsRosDesktop,
  requestBiddingFromPWT,
  createApsSlotsHomeMobile,
  createApsSlotsHomeDesktop,
} from "./../Ads/index";
import { stateData } from "constants/state";
// import WidgetHome from "components/cricket/WidgetHome";
import { callGaEvent } from "constants/cricket";
import { setScoreData } from "redux/actions/seriesAction";
import { useDispatch } from "react-redux";
const LatestVideos = dynamic(() =>
  import("./LatestVideos", { ssr: false })
);
const WidgetHome = dynamic(
  () => import("components/cricket/WidgetHome"),
  { ssr: false }
);

const VisualStories = dynamic(() =>
  import("./CategoryPosts/visualStories", { ssr: false })
);
// import { Container } from "react-bootstrap";

const WidgetHomeIpl = dynamic(() =>
  import("components/cricket/WidgetHomeIpl", { ssr: false })
);

// const ShortsSlider = dynamic(() =>
//   import("../short-video/shortSlider.js", { ssr: true })
// );
// const TopShows = dynamic(() => import("./topShows/topShows.js", { ssr: true }));
// const TopShowsWidget = dynamic(() =>
//   import("./topShows/topShowsWidget.js", { ssr: true })
// );
const CategoryPosts = dynamic(
  () => import("./CategoryPosts/categoryPosts.js"),
  { ssr: false }
);

const HomePage = ({
  featuredPosts = [],
  bannerData = [],
  liveShowData = [],
  shortsData = [],
  topShowsData = [],
  NewsPageData = [],
  // cricketResponse = [],
  quizHomeData = [],
  electionTopicData = [],
  topShowsWidgetData = [],
  responseVs = {},
  liveBlogWidgetData = [],
  homePageVideosData = [],
  authorWidgetData = [],
  topPodcastData = [],
  pointsData,
  scoreData,
  cricketData,
  loadAll
}) => {
  // liveBlogWidgetData = [];
  const masterConfig = useContext(MasterConfigContext);


  const STATIC_BREADCRUMP = [
    {
      displayText: "कौन जीता-कौन हारा",
      url: "/election/delhi-assembly-chunav/results",
      keyToShow: "Delhi_KaunJeeta",
    },
    {
      displayText: "प्रमुख उम्मीदवार",
      url: "/election/delhi-assembly-chunav/key-candidates",
      keyToShow: "DelhiKeyCandidate",
    },
    {
      displayText: "एग्जिट पोल",
      url: "/election/delhi-assembly-chunav/exit-poll",
      keyToShow: "DelhiExitPollPage",
    },
    {
      displayText: "विधान सभा रिजल्ट",
      url: "/election/delhi-assembly-chunav/party-wise-result",
      keyToShow: "DelhiResultPage",
    },
    {
      displayText: "शेड्यूल",
      url: "/election/delhi-assembly-chunav/schedule",
      keyToShow: "DelhiSchedulePage",
    },
    {
      displayText: "सामान्य प्रश्न",
      url: "/election/assembly-chunav/faq",
      keyToShow: "",
    },
  ];

  //needs to be changed before deploying to prod
  const masterObj = masterConfig[stateData['home-page'].masterConfigKey];
  console.log("topShowsWidgetData 123 ", masterConfig);

  const router = useRouter();
  const [language, setLanguage] = useState("");


  const [timeSpent, setTimeSpent] = useState(0);
  const [isActive, setIsActive] = useState(true);

  const [loadScrollableComponents , setLoadScrollableComponents] = useState(loadAll)

 

  // useEffect(() => {
  //   let timer;

  //   const handleVisibilityChange = () => {
  //     setIsActive(!document.hidden);
  //   };

  //   if (isActive) {
  //     timer = setInterval(() => {
  //       setTimeSpent((prevTime) => prevTime + 1);
  //     }, 1000);
  //   } else {
  //     clearInterval(timer);
  //   }

  //   document.addEventListener("visibilitychange", handleVisibilityChange);

  //   return () => {
  //     clearInterval(timer);
  //     const obj = {
  //       eventName: "Time_On_Page",
  //       pageTitle: "Home Page",
  //       timeSpent: timeSpent,
  //       propertyObject: {
  //         timeSpent: timeSpent
  //       }
  //     }
  //     callGaEvent(obj)
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, [isActive]);


  // const [scrollDepth, setScrollDepth] = useState(0);
  const thresholds = [25, 50, 75, 100];
  const triggered = new Set();

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const docHeight = document.documentElement.scrollHeight - window.innerHeight;
      const scrolled = (scrollTop / docHeight) * 100;

      thresholds.forEach((threshold) => {
        if (scrolled >= threshold && !triggered.has(threshold)) {
          // console.log(`Scrolled ${threshold}%`);
          triggered.add(threshold);
          const obj = {
            eventName: "Page_Scroll_Depth",
            pageTitle: "Home Page",
            propertyObject: { depth: threshold }
          }
          callGaEvent(obj)
        }
      });

      // setScrollDepth(scrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    setLanguage(getLanguage());
  }, []);
  console.log("responseVs", liveBlogWidgetData);
  const size = useWindowSize();
  console.log("sizeeeee", size);

  const [firstScroll, setFirstScroll] = useState(true);
  const [adCalled, setAdCalled] = useState(false);


  useEffect(() => {

    if (size.width) {

      apstag.fetchBids(
        {
          slots:
            size.width < 768
              ? createApsSlotsHomeMobile({})
              : createApsSlotsHomeDesktop({}),
          timeout: 2e3,
        },
        apstagBidCallback
      );
    }

  }, [size.width])

  useEffect(() => {
    async function onFirstScroll() {
      setFirstScroll(true);
      setLoadScrollableComponents(true)

      window._taboola = window._taboola || [];
      _taboola.push({ homepage: "auto" });
      !(function (e, f, u, i) {
        if (!document.getElementById(i)) {
          e.async = 1;
          e.src = u;
          e.id = i;
          f.parentNode.insertBefore(e, f);
        }
      })(
        document.createElement("script"),
        document.getElementsByTagName("script")[0],
        "//cdn.taboola.com/libtrc/indiatoday-thelallantop/loader.js",
        "tb_loader_script"
      );
      if (window.performance && typeof window.performance.mark === "function") {
        window.performance.mark("tbl_ic");
      }

      window._taboola = window._taboola || [];
      _taboola.push({
        mode: "alternating-thumbnails-a",
        container: "taboola-below-homepage-thumbnails",
        placement: "Below Homepage Thumbnails",
        target_type: "mix",
      });
    }

    function handleScroll() {
      onFirstScroll();
      window.removeEventListener("scroll", handleScroll);
    }

    window.addEventListener("scroll", handleScroll);
  }, []);


  useEffect(() => {
    // var showAds = true;

    // if (process.env.NEXT_PUBLIC_ADS_ONSCROLL === "true" && !firstScroll) {
    //   showAds = false;
    // }
    setTimeout(() => {
      window.googletag = window.googletag || { cmd: [] };
      if (window.screen.width > 600) {
        if (
          router.pathname.includes("/text") ||
          router.pathname.includes("/video")
        ) {

          googletag.cmd.push(function () {
            googletag
              .defineSlot(
                "/1007232/Lallantop_Desktop_ROS_ATF_728x90",
                [
                  [728, 90],
                  [970, 250],
                  [970, 90],
                ],
                "div-gpt-ad-1701945163318-0"
              )
              .addService(googletag.pubads());
            googletag
              .defineSlot(
                "/1007232/Lallantop_Desktop_ROS_ATF_300x250",
                [300, 250],
                "div-gpt-ad-1701945220410-0"
              )
              .addService(googletag.pubads());
            {
              document.getElementById("div-gpt-ad-1701945220410-1") &&
                googletag
                  .defineSlot(
                    "/1007232/Lallantop_Desktop_ROS_MTF_1_728x90",
                    [728, 90],
                    "div-gpt-ad-1701945220410-1"
                  )
                  .addService(googletag.pubads());
            }

            googletag
              .defineSlot(
                "/1007232/Lallantop_Desktop_ROS_MTF_2_728x90",
                [728, 90],
                "div-gpt-ad-1701945220410-2"
              )
              .addService(googletag.pubads());
            googletag
              .defineSlot(
                "/1007232/Lallantop_Desktop_ROS_MTF_3_300x250",
                [300, 250],
                "div-gpt-ad-1701945220410-3"
              )
              .addService(googletag.pubads());
            // googletag.defineSlot('/1007232/Lallantop_Desktop_ROS_BTF_300x250', [[300, 250], [300, 600]], 'div-gpt-ad-1701945864013-0').addService(googletag.pubads());
            // googletag.defineOutOfPageSlot('/1007232/Lallantop_Mobile_HP_Mweb_Interstitial',googletag.enums.OutOfPageFormat.INTERSTITIAL).addService(googletag.pubads());
            // var SECONDS_TO_WAIT_AFTER_VIEWABILITY = 30;
            // googletag
            //   .pubads()
            //   .addEventListener("impressionViewable", function (event) {
            //     var slot = event.slot;
            //     if (
            //       slot.getTargeting(REFRESH_KEY).indexOf(REFRESH_VALUE) > -1
            //     ) {
            //       setTimeout(function () {
            //         googletag.pubads().refresh([slot]);
            //       }, SECONDS_TO_WAIT_AFTER_VIEWABILITY * 1000);
            //     }
            //   });
            googletag.pubads().enableSingleRequest();
            googletag.pubads().setTargeting("Category", "LThomepage");
            googletag
              .pubads()
              .setTargeting("Keywords", [
                "होम",
                "होम लल्लनटॉप",
                "Latest Hindi News",
                "Breaking Hindi News Live",
                "Today Hindi News and Headlines",
              ]);
            googletag.enableServices();
            googletag.pubads().enableSingleRequest();
            googletag.pubads().disableInitialLoad();
            googletag.enableServices();
            requestBiddingFromPWT();
          });
        } else {

          googletag.cmd.push(function () {
            // googletag
            //   .defineSlot(
            //     "/1007232/Lallantop_Desktop_HP_MTF_300x250",
            //     [300, 250],
            //     "div-gpt-ad-1726480392574-0"
            //   )
            //   .addService(googletag.pubads());
            googletag
              .defineSlot(
                "/1007232/Lallantop_Desktop_HP_ATF_728x90",
                [
                  [728, 90],
                  [970, 250],
                  [970, 90],
                ],
                "div-gpt-ad-1701945163318-0"
              )
              .addService(googletag.pubads());
            googletag
              .defineSlot(
                "/1007232/Lallantop_Desktop_HP_ATF_300x250",
                [300, 250],
                "div-gpt-ad-1701945220410-0"
              )
              .addService(googletag.pubads());
            // googletag
            //   .defineSlot(
            //     "/1007232/Lallantop_Desktop_HP_MTF_1_728x90",
            //     [
            //       [728, 90],
            //       [970, 250],
            //       [970, 90],
            //     ],
            //     "div-gpt-ad-1701945220410-1"
            //   )
            //   .addService(googletag.pubads());
            // googletag
            //   .defineSlot(
            //     "/1007232/Lallantop_Desktop_HP_MTF_2_728x90",
            //     [
            //       [728, 90],
            //       [970, 250],
            //       [970, 90],
            //     ],
            //     "div-gpt-ad-1701945220410-2"
            //   )
            //   .addService(googletag.pubads());
            // googletag
            //   .defineSlot(
            //     "/1007232/Lallantop_Desktop_HP_MTF_3_728x90",
            //     [
            //       [728, 90],
            //       [970, 90],
            //     ],
            //     "div-gpt-ad-1701945220410-4"
            //   )
            //   .addService(googletag.pubads());
            // googletag
            //   .defineSlot(
            //     "/1007232/Lallantop_Desktop_HP_BTF_300x250",
            //     [300, 250],
            //     "div-gpt-ad-1701945220410-3"
            //   )
            //   .addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.pubads().setTargeting("Category", "LThomepage");
            googletag
              .pubads()
              .setTargeting("Keywords", [
                "होम",
                "होम लल्लनटॉप",
                "Latest Hindi News",
                "Breaking Hindi News Live",
                "Today Hindi News and Headlines",
              ]);
            googletag.enableServices();
            googletag.pubads().enableSingleRequest();
            googletag.pubads().disableInitialLoad();
            googletag.enableServices();
            requestBiddingFromPWT();
          });
        }
      } else {
        if (
          router.pathname.includes("/text") ||
          router.pathname.includes("/video")
        ) {

          googletag.cmd.push(function () {
            {
              document.getElementById("div-gpt-ad-1701946028239-0") &&
                googletag
                  .defineSlot(
                    "/1007232/Lallantop_Mobile_ROS_ATF_300x250",
                    [
                      [336, 280],
                      [300, 250],
                    ],
                    "div-gpt-ad-1701946028239-0"
                  )
                  .addService(googletag.pubads());
            }
            {
              document.getElementById("div-gpt-ad-1701946028239-1") &&
                googletag
                  .defineSlot(
                    "/1007232/Lallantop_Mobile_ROS_MTF_1_300x250",
                    [
                      [336, 280],
                      [300, 250],
                    ],
                    "div-gpt-ad-1701946028239-1"
                  )
                  .addService(googletag.pubads());
            }
            {
              document.getElementById("div-gpt-ad-1701946028239-2") &&
                googletag
                  .defineSlot(
                    "/1007232/Lallantop_Mobile_ROS_MTF_2_300x250",
                    [
                      [336, 280],
                      [300, 250],
                    ],
                    "div-gpt-ad-1701946028239-2"
                  )
                  .addService(googletag.pubads());
            }
            googletag
              .defineSlot(
                "/1007232/Lallantop_Mobile_ROS_Bottom_320x50",
                [320, 50],
                "div-gpt-ad-1701946324333-0"
              )
              .addService(googletag.pubads());
            googletag
              .defineOutOfPageSlot(
                "/1007232/Lallantop_Mobile_ROS_Mweb_Interstitial",
                googletag.enums.OutOfPageFormat.INTERSTITIAL
              )
              .addService(googletag.pubads());
            // var SECONDS_TO_WAIT_AFTER_VIEWABILITY = 30;
            // googletag
            //   .pubads()
            //   .addEventListener("impressionViewable", function (event) {
            //     var slot = event.slot;
            //     if (
            //       slot.getTargeting(REFRESH_KEY).indexOf(REFRESH_VALUE) > -1
            //     ) {
            //       setTimeout(function () {
            //         googletag.pubads().refresh([slot]);
            //       }, SECONDS_TO_WAIT_AFTER_VIEWABILITY * 1000);
            //     }
            //   });
            googletag.pubads().enableSingleRequest();
            googletag.pubads().setTargeting("Category", "LThomepage");
            googletag
              .pubads()
              .setTargeting("Keywords", [
                "होम",
                "होम लल्लनटॉप",
                "Latest Hindi News",
                "Breaking Hindi News Live",
                "Today Hindi News and Headlines",
              ]);
            googletag.enableServices();
            googletag.pubads().enableSingleRequest();
            googletag.pubads().disableInitialLoad();
            googletag.enableServices();
            requestBiddingFromPWT();
          });
        } else {

          googletag.cmd.push(function () {
            var REFRESH_KEY = "refresh";
            var REFRESH_VALUE = "true";
            // googletag
            //   .defineSlot(
            //     "/1007232/Lallantop_Desktop_HP_MTF_300x250",
            //     [300, 250],
            //     "div-gpt-ad-1726480392574-0"
            //   )
            //   .addService(googletag.pubads());
            googletag
              .defineSlot(
                "/1007232/Lallantop_Mobile_HP_ATF_300x250",
                [
                  [336, 280],
                  [300, 250],
                ],
                "div-gpt-ad-1701946028239-0"
              )
              .addService(googletag.pubads());
            // googletag
            //   .defineSlot(
            //     "/1007232/Lallantop_Mobile_HP_MTF_1_300x250",
            //     [
            //       [336, 280],
            //       [300, 250],
            //     ],
            //     "div-gpt-ad-1701946028239-1"
            //   )
            //   .addService(googletag.pubads());
            // googletag
            //   .defineSlot(
            //     "/1007232/Lallantop_Mobile_HP_MTF_2_300x250",
            //     [
            //       [336, 280],
            //       [300, 250],
            //     ],
            //     "div-gpt-ad-1701946028239-2"
            //   )
            //   .addService(googletag.pubads());
            // googletag
            //   .defineSlot(
            //     "/1007232/Lallantop_Mobile_HP_BTF_300x250",
            //     [
            //       [336, 280],
            //       [300, 250],
            //     ],
            //     "div-gpt-ad-1701946028239-3"
            //   )
            //   .addService(googletag.pubads());
            // googletag
            //   .defineSlot(
            //     "/1007232/Lallantop_Mobile_HP_Bottom_320x50",
            //     [320, 50],
            //     "div-gpt-ad-1701946324333-0"
            //   )
            //   .addService(googletag.pubads());
            googletag
              .defineOutOfPageSlot(
                "/1007232/Lallantop_Mobile_HP_Mweb_Interstitial",
                googletag.enums.OutOfPageFormat.INTERSTITIAL
              )
              .addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.pubads().setTargeting("Category", "LThomepage");
            googletag
              .pubads()
              .setTargeting("Keywords", [
                "होम",
                "होम लल्लनटॉप",
                "Latest Hindi News",
                "Breaking Hindi News Live",
                "Today Hindi News and Headlines",
              ]);
            googletag.enableServices();
            googletag.pubads().enableSingleRequest();
            googletag.pubads().disableInitialLoad();
            googletag.enableServices();
            // googletag.pubads().refresh();
            requestBiddingFromPWT();
          });
        }
      }
      // apstag.fetchBids(
      //   {
      //     slots:
      //       size?.width < 499
      //         ? createApsSlotsRosMobile({})
      //         : createApsSlotsRosDesktop({}),
      //     timeout: 2e3,
      //   },
      //   apstagBidCallback
      // );

      // apstag.fetchBids(
      //   {
      //     slots:
      //       size.width < 499
      //         ? createApsSlotsHomeMobile({})
      //         : createApsSlotsHomeDesktop({}),
      //     timeout: 2e3,
      //   },
      //   apstagBidCallback
      // );
    }, 500);


    // if (showAds && !adCalled) {
    //   setAdCalled(true);
    //   console.log("adscalled");

    // }
  }, []);

  useEffect(() => {
    if (loadScrollableComponents) {
      if (!window.googletag) {
        window.googletag = { cmd: [] };
      }
  
      // window.googletag.cmd.push(() => {
      //   googletag.destroySlots();
      // });
  
      setTimeout(() => {
        window.googletag.cmd.push(() => {
          let slots = [];
          if (window.screen.width > 600) {
            slots = [
              {
                id: "/1007232/Lallantop_Desktop_HP_MTF_300x250",
                size: [300, 250],
                div: "div-gpt-ad-1726480392574-0",
              },
              {
                id: "/1007232/Lallantop_Desktop_HP_MTF_1_728x90",
                size: [[728, 90], [970, 250], [970, 90]],
                div: "div-gpt-ad-1701945220410-1",
              },
              {
                id: "/1007232/Lallantop_Desktop_HP_MTF_2_728x90",
                size: [[728, 90], [970, 250], [970, 90]],
                div: "div-gpt-ad-1701945220410-2",
              },
              {
                id: "/1007232/Lallantop_Desktop_HP_MTF_3_728x90",
                size: [[728, 90], [970, 250], [970, 90]],
                div: "div-gpt-ad-1701945220410-4",
              },
              {
                id: "/1007232/Lallantop_Desktop_HP_BTF_300x250",
                size: [300, 250],
                div: "div-gpt-ad-1701945220410-3",
              },
            ];
          } else {
            slots = [
              {
                id: "/1007232/Lallantop_Mobile_HP_MTF_300x250",
                size: [300, 250],
                div: "div-gpt-ad-1726480392574-0",
              },
              {
                id: "/1007232/Lallantop_Mobile_HP_MTF_1_300x250",
                size: [[336, 280], [300, 250]],
                div: "div-gpt-ad-1701946028239-1",
              },
              {
                id: "/1007232/Lallantop_Mobile_HP_MTF_2_300x250",
                size: [[336, 280], [300, 250]],
                div: "div-gpt-ad-1701946028239-2",
              },
              // {
              //   id: "/1007232/Lallantop_Mobile_HP_MTF_3_300x250",
              //   size: [[336, 280], [300, 250]],
              //   div: "div-gpt-ad-1701946028239-4",
              // },
              {
                id: "/1007232/Lallantop_Mobile_HP_BTF_300x250",
                size: [[336, 280], [300, 250]],
                div: "div-gpt-ad-1701946028239-3",
              },
              {
                id: "/1007232/Lallantop_Mobile_HP_Bottom_320x50",
                size: [320, 50],
                div: "div-gpt-ad-1701946324333-0",
              },
            ];
          }
  
          slots.forEach((slot) => {
            const adSlot = googletag.defineSlot(slot.id, slot.size, slot.div);
            if (adSlot) {
              adSlot.addService(googletag.pubads());
            } else {
              console.error(`Failed to define slot: ${slot.div}`);
            }
          });
  
          googletag.pubads().enableSingleRequest();
          googletag.pubads().disableInitialLoad();
          googletag.pubads().enableLazyLoad();
          googletag.pubads().setTargeting("Category", "LThomepage");
          googletag.pubads().setTargeting("Keywords", [
            "होम",
            "होम लल्लनटॉप",
            "Latest Hindi News",
            "Breaking Hindi News Live",
            "Today Hindi News and Headlines",
          ]);
  
          googletag.enableServices();
  
          // Ensure all ad divs exist
          document.querySelectorAll("[id^='div-gpt-ad']").forEach((el) => {
            if (!el) console.error(`Ad div missing: ${el.id}`);
          });
  
          googletag.pubads().refresh();
        });
      }, 300);
    }
  }, [loadScrollableComponents]);


  const dispatch = useDispatch()


  useEffect(() => {
    dispatch(setScoreData(scoreData))
}, [])
  
  


  const ItemListSchema = () => {
    var itemArray = [];
    let keyValueArray = [];
    var arrayforSchema;
    if (router?.asPath === "/") {
      arrayforSchema = featuredPosts;
    } else {
      arrayforSchema = NewsPageData?.entities;
    }
    console.log("arrayforSchema", arrayforSchema);
    for (let i = 1; i <= 10; i++) {
      let position = {};
      let typeKey = "@type";
      let typeValue = "ListItem";
      position[typeKey] = typeValue;
      // position.push({ [typeKey]: typeValue });
      typeKey = "position";
      typeValue = i;
      position[typeKey] = typeValue;
      typeKey = "name";
      typeValue = arrayforSchema?.[i - 1]?.title;
      // position.push({ [typeKey]: typeValue });
      position[typeKey] = typeValue;

      // typeKey = "description";
      // typeValue = featuredPosts[i - 1]?.shortText;
      // position[typeKey] = typeValue;
      typeKey = "url";
      typeValue = arrayforSchema?.[i - 1]?.permalink;
      position[typeKey] = typeValue;
      keyValueArray.push(position);
    }
    itemArray = keyValueArray;
    console.log("ItemListSchema", featuredPosts);

    // for (let x = 1; x <= 10; x++) {
    //   var item = `"@type": "ListItem","name": ${
    //     featuredPosts[x]?.title
    //   },"position": ${x + 1},"url":${featuredPosts[x]?.permalink},`;
    //   itemArray.push(item);
    // }
    // var itemSchema = `{"@context": "https://schema.org","@type": "ItemList","itemListElement": ${itemArray}}`;
    // console.log("ItemListSchema 1", itemSchema);

    // itemSchema = itemSchema.replace(/\\/g, "");
    return itemArray;
  };
  console.log("CHUNVA DAATA ", homePageVideosData);
  return (
    <>
      <Head>
        <meta property="fb:pages" content="1453842511589254" />
        <meta
          name="keywords"
          content={`${router.pathname === "/"
            ? "Hindi News, Hindi Samachar, Breaking News in Hindi, Today Headlines, Latest News in Hindi, हिंदी समाचार, हिंदी न्यूज़, ताजा ख़बरें"
            : router.pathname === "/text"
              ? "Hindi news Article, news story in hindi, breaking news article in hindi, latest news article in hindi, latest hindi news Stories, today news story in hindi, hindi news story today, हिंदी न्यूज़ पढ़िए, हिन्दी समाचार पढ़िए, पढ़िए हिंदी में समाचार"
              : router.pathname === "/video"
                ? "Hindi news Video, Video story in hindi, breaking news Video in hindi, latest news Video in hindi, latest hindi news Video, watch today news story in hindi, hindi news today, हिंदी न्यूज़ देखिये, हिन्दी समाचार देखिये, देखिये हिंदी में समाचार, Trending Video, Viral Video today"
                : "Hindi news Audio, audio story in hindi, breaking news podcast in hindi, latest news podcast in hindi, latest hindi news audio stories, Listen today news story in hindi, hindi news today, हिंदी न्यूज़ सुनिए, हिन्दी समाचार सुनिए, सुनिए हिंदी में समाचार, Trending audio stories, Viral podcast today"
            }`}
        />
        {/* <link rel="amphtml" href="https://m.thelallantop.com/" /> */}
        <link
          rel="preconnect"
          crossOrigin="true"
          href="https://static.chartbeat.com"
        ></link>

        <script
          dangerouslySetInnerHTML={{
            __html: `
            (function () {
              var _sf_async_config = window._sf_async_config = (window._sf_async_config || {});
              _sf_async_config.uid = 60355;
              _sf_async_config.domain = 'thelallantop.com';
              _sf_async_config.useCanonical = true;
              _sf_async_config.useCanonicalDomain = true;
              _sf_async_config.sections = 'about'; 
              _sf_async_config.authors = '';
              function loadChartbeat() {
                  var e = document.createElement('script');
                  var n = document.getElementsByTagName('script')[0];
                  e.type = 'text/javascript';
                  e.async = true;
                  e.src = '//static.chartbeat.com/js/chartbeat.js';
                  n.parentNode.insertBefore(e, n);
              }
              loadChartbeat();
          })();
       `,
          }}
          strategy="afterInteractive"
          id="chartbeat"
          type="text/javascript"
        ></script>
        <link
          rel="preconnect"
          crossOrigin="true"
          href="https://www.gstatic.com"
        ></link>
        <link
          rel="preconnect"
          crossOrigin="true"
          href="https://f.vimeocdn.com"
        ></link>
      </Head>
      <NextSeo
        title={
          router.pathname === "/"
            ? "Hindi News, ताज़ा हिंदी समाचार | Breaking News Headlines Today in Hindi | Latest News Today Hindi | हिंदी न्यूज़ | The Lallantop"
            : router.pathname === "/text"
              ? "Hindi News Article, हिंदी न्यूज़ पढ़िए , Today Breaking News Article In Hindi, Today News Headlines"
              : router.pathname === "/video"
                ? "Hindi News Video, Trending Video हिंदी न्यूज़ , Today Breaking News Video, Today News Video"
                : "Hindi News Podcast, Trending podcast हिंदी न्यूज़ , Today Breaking News सुनिए, Today News Podcast"
        }
        canonical={
          `https://www.thelallantop.com` +
          (router.asPath.includes("/text")
            ? "/text"
            : router.asPath.includes("/video")
              ? "/video"
              : "")
        }
        description={
          router.pathname === "/"
            ? "Hindi News (हिंदी न्यूज़) - Read here देश, विदेश, खेल, राजनीति, क्राइम, ऑटोमोबाइल, टेक्नोलॉजी, शिक्षा, लाइफस्टाइल, क्रिकेट से सम्बंधित आज की मुख्य खबरें, ताज़ा हिंदी समाचार (Latest Hindi News), ब्रेकिंग न्यूज़ (Breaking News) और लेटेस्ट न्यूज़ (Latest News in Hindi) हिंदी में on The Lallantop."
            : router.pathname === "/text"
              ? "Hindi News Article (हिंदी न्यूज़) - पढ़िए देश, विदेश, खेल, राजनीति, क्राइम, ऑटोमोबाइल, टेक्नोलॉजी, शिक्षा, लाइफस्टाइल, क्रिकेट और ज्योतिष से सम्बंधित सभी समाचार हिंदी में (News Stories In Hindi) Lallantop पर.  Read Breaking News Headlines in Hindi on lallantop Website."
              : router.pathname === "/video"
                ? "Hindi News Viral Video (हिंदी न्यूज़) - Watch देश, विदेश, खेल, राजनीति, क्राइम, ऑटोमोबाइल, टेक्नोलॉजी, शिक्षा, लाइफस्टाइल, क्रिकेट और ज्योतिष से सम्बंधित सभी समाचार हिंदी में (Video Stories In Hindi) Lallantop पर.  Watch Trending News Video in Hindi on lallantop Website."
                : " Hindi News Viral Podcast (हिंदी न्यूज़) - listen देश, विदेश, खेल, राजनीति, क्राइम, ऑटोमोबाइल, टेक्नोलॉजी, शिक्षा, लाइफस्टाइल, क्रिकेट और ज्योतिष से सम्बंधित सभी समाचार हिंदी में (Podcast Stories In Hindi) Lallantop पर.  Watch Trending News PODCAST in Hindi on lallantop Website."
        }
      />
      {/* <h1 style={{display:"none"}}>Lallantop Hindi News</h1> */}
      {firstScroll && (
        <Script
          id="taboola"
          //strategy="afterInteractive"
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
              window._taboola = window._taboola || [];
              _taboola.push({homepage:'auto'});
              !function (e, f, u, i) {
              if (!document.getElementById(i)){
              e.async = 1;
              e.src = u;
              e.id = i;
              f.parentNode.insertBefore(e, f);
              }
              }(document.createElement('script'),
              document.getElementsByTagName('script')[0],
              '//cdn.taboola.com/libtrc/indiatoday-thelallantop/loader.js',
              'tb_loader_script');
              if(window.performance && typeof window.performance.mark == 'function')
              {window.performance.mark('tbl_ic');}
              `,
          }}
        ></Script>
      )}
      {/* {size.width > 600 && (
        <GoogleAdContainer divId="div-gpt-ad-1686738608479-0" /> */}
      <div
        className="container-row py-2 text-center deaktop-header-ad"
        style={{
          overflow: "hidden",
        }}
      >
        <p
          style={{
            fontSize: "11px",
            color: "#b7b7b7",
            marginBottom: "0px",
          }}
        >
          Advertisement
        </p>

        {size.width > 600 && (
          <div
            id={"div-gpt-ad-1701945163318-0"}
            style={{
              minHeight: "100px",
              alignContent: "center",
              textAlign: "-webkit-center",
            }}
          >
            <script
              type="text/javascript"
              defer
              dangerouslySetInnerHTML={{
                __html: `{googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701945163318-0')})`,
              }}
            ></script>
          </div>
        )}
      </div>

      {(cricketData && scoreData && pointsData) && (masterConfig['IPL']['MainBanners']) && <WidgetHomeIpl newsData={cricketData} scoreData={scoreData} pointsData={pointsData} table={pointsData.standings.stage1.groups[0]} size={size} />}

      {/* CHUNAV STRIP START  */}
      {
        masterObj?.Homepage_Strip &&
        (
          (size.width < 768) ?
            (
              <>
                <div>
                  {console.log('abccccccccc')}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "1rem",
                      gap: "8px",
                      // cursor: "pointer",
                      paddingBottom: "1rem",
                      backgroundColor: "white",
                      marginTop: "10px",

                    }}

                  >
                    <img
                      src="/assets/images/assembly-chunav.png"
                      width="240px"
                      height="auto"
                      style={{ alignSelf: "center" }}
                    ></img>
                  </div>
                  <div
                    class="divCategoriesSchedule2 snipcss-eqdpv"
                    style={{
                      margin: "-1px 0px",
                      backgroundColor: "white",
                      paddingLeft: "15px",
                      paddingBottom: "1rem",
                      justifyContent:
                        router.asPath.includes("jharkhand-") ||
                          router.asPath.includes("maharashtra-")
                          ? "center"
                          : "space-around",
                    }}
                  >
                    <br></br>
                    {STATIC_BREADCRUMP.map((item, index) => {
                      return masterObj[item.keyToShow] != false ? (
                        <a key={index} href={item.url}>
                          <span>{item.displayText}</span>
                        </a>
                      ) : null;
                    })}

                  </div>
                </div>
              </>
            ) :
            (

              <>
                {size?.width >= 768 && <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "white",
                    width: "max-content",
                    margin: "auto",
                    gap: "68px",
                    height: "80px",
                    marginTop: "15px",
                    marginBottom: "15px",
                    borderRadius: "12px",
                    padding: "0px 30px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      // paddingTop: "1rem",
                      gap: "8px",
                      // cursor: "pointer",
                      backgroundColor: "white",
                      paddingLeft: "0px",
                    }}

                  >

                    <img
                      src="/assets/images/assembly-chunav.png"
                      width="408.56px"
                      height="58.21px"
                      style={{ alignSelf: "center" }}
                    ></img>
                  </div>
                  <br />
                  <div
                    class="divCategoriesSchedule2 snipcss-eqdpv"
                    style={{
                      margin: "0px 0px",
                      // backgroundColor: "rgb(217, 54, 139)",
                      paddingLeft: "15px",
                      alignContent: "center",
                    }}
                  >
                    {STATIC_BREADCRUMP.map((item, index) => {
                      return masterObj[item.keyToShow] != false ? (
                        <a key={index} href={item.url}>
                          <span>{item.displayText}</span>
                        </a>
                      ) : null;
                    })}

                  </div>
                </div>}
              </>
            )
        )
      }
      {/* CHUNAV STRIP END  */}

      {size.width < 768 && (masterObj?.MoveAdsUp == true) && (
        <div
          className="container-row py-2 mobile-header-ad"
          align="center"
        >
          <p
            style={{
              fontSize: "11px",
              color: "#b7b7b7",
              marginBottom: "0px",
            }}
          >
            Advertisement
          </p>

          {size.width < 768 && (
            <div
              id={"div-gpt-ad-1701946028239-0"}
              style={{
                minHeight: "260px",
                alignContent: "center",
                textAlign: "-webkit-center",
              }}
            >
              <script
                type="text/javascript"
                defer
                dangerouslySetInnerHTML={{
                  __html: `{googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701946028239-0')})`,
                }}
              ></script>
            </div>
          )}
        </div>
      )}
      {/* {masterConfig?.vidhansabha_election?.HRExittally && ( */}
      {masterObj.Exittally && (
        <>
          {size.width < 768 ? (
            <div className="result-page">
              <h2
                style={{
                  fontSize: "27px",
                  color: "#D9368B",
                  fontWeight: "800",
                }}
              >
                विधानसभा एग्ज़िट पोल
              </h2>
              <div>
                <iframe
                  scrolling="no"
                  src={`${masterObj.Exittally_URL}`}
                  width="100%"
                  height={masterObj.Exitpolltally_height_mobile}
                ></iframe>
              </div>
            </div>
          ) : (
            <div
              style={{
                backgroundColor: "white",
              }}
            >
              <div className="result-page main-container-featured1">
                <h2
                  style={
                    size.width >= 800 && size.width <= 1000
                      ? {
                        fontSize: "27px",
                        color: "#D9368B",
                        fontWeight: "800",
                        marginTop: "45px",
                      }
                      : {
                        fontSize: "27px",
                        color: "#D9368B",
                        fontWeight: "800",
                      }
                  }
                >
                  विधानसभा एग्ज़िट पोल
                </h2>
                <div>
                  <iframe
                    scrolling="no"
                    src={`${masterObj.Exittally_URL}`}
                    width="100%"
                    height={masterObj.Exitpolltally_height_web}
                  ></iframe>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {masterObj.Resulttally && (
        <>
          {size.width < 768 ? (
            <div className="result-page-big">
              <h2
                style={{
                  fontSize: "27px",
                  color: "#D9368B",
                  fontWeight: "800",
                }}
              >
                दिल्ली विधानसभा रिजल्ट
              </h2>
              <div>
                <iframe
                  scrolling="no"
                  src={masterObj.Resulttally_URL}
                  width="100%"
                  height={masterObj.Resulttally_height_mobile}
                ></iframe>
              </div>
            </div>
          ) : (
            <div
              style={{
                backgroundColor: "white",
              }}
            >
              <div className="result-page-big main-container-featured1">
                <h2
                  style={{
                    fontSize: "27px",
                    color: "#D9368B",
                    fontWeight: "800",
                  }}
                >
                  दिल्ली विधानसभा रिजल्ट
                </h2>
                <div>
                  <iframe
                    scrolling="no"
                    src={masterObj.Resulttally_URL}
                    width="100%"
                    height={
                      size.width != 820 && size.width <= 1000
                        ? masterObj.Resulttally_height_tab
                        : masterObj.Resulttally_height_web
                    }
                  ></iframe>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {masterObj.BypollResulttally && (
        <>
          {size.width < 768 ? (
            <div className="result-page-big">
              <h2
                style={{
                  fontSize: "27px",
                  color: "#D9368B",
                  fontWeight: "800",
                }}
              >
                उपचुनाव रिजल्ट
              </h2>
              <div>
                <iframe
                  scrolling="no"
                  src={masterObj.BypollResulttally_URL}
                  width="100%"
                  height={masterObj.Bypoll_Resulttally_height_mobile}
                ></iframe>
              </div>
            </div>
          ) : (
            <div className="result-page-big">
              <h2
                style={{
                  fontSize: "27px",
                  color: "#D9368B",
                  fontWeight: "800",
                }}
              >
                उपचुनाव रिजल्ट
              </h2>
              <div>
                <iframe
                  scrolling="no"
                  src={masterObj.BypollResulttally_URL}
                  width="100%"
                  height={masterObj.Bypoll_Resulttally_height_web}
                ></iframe>
              </div>
            </div>
          )}
        </>
      )}
      <br></br>

      {/* )} */}
      {/* <TopPodcastContainer topPodcastData={topPodcastData} />
      <AuthorColumn authorWidgetData={authorWidgetData}></AuthorColumn> */}
      {/* {size.width < 1100 && (
        <>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "1rem",
                gap: "8px",
                cursor: "pointer",
                paddingBottom: "1rem",
                backgroundColor: "rgb(217, 54, 139)",
              }}
              onClick={() =>
                window.open("/election/lok-sabha-chunav-2024", "_self")
              }
            >
        
              <img
                src="/assets/images/chunav-name.png"
                width="197.58px"
                height="45.21px"
                style={{ alignSelf: "center" }}
              ></img>
            </div>
            <div
              class="divCategoriesSchedule snipcss-eqdpv"
              style={{
                margin: "-1px 0px",
                backgroundColor: "rgb(217, 54, 139)",
                paddingLeft: "15px",
                paddingBottom: "1rem",
              }}
            >
              <a href="/election/lok-sabha-chunav-2024">
                <span>न्यूज़</span>
              </a>{" "}
              <a href="/election/lok-sabha-chunav-2024/results">
                <span>कौन जीता-कौन हारा</span>
              </a>{" "}
              <a href="/election/lok-sabha-chunav-2024/party-wise-result">
                <span>लोक सभा रिजल्ट</span>
              </a>
            
              <a href="/election/lok-sabha-chunav-2024/key-candidates">
                <span>प्रमुख उम्मीदवार</span>
              </a>
          
              <a href="/election/vidhan-sabha-chunav-2024/party-wise-result">
                <span>विधान सभा रिजल्ट</span>
              </a>
             
            </div>
          </div>
          <div style={{ backgroundColor: "rgb(187 10 103)" }}>
            <div
              style={{
                fontSize: "12px",
                fontWeight: "700",
                color: "white",
                paddingTop: "10px",
                textAlign: "center",
              }}
            >
              POWERED BY
            </div>
            <div
              style={{
                display: "flex",
                gap: "30px",
                justifyContent: "center",
                padding: "10px",
              }}
            >
              <img
                width="80px"
                height="60px"
                style={{ backgroundColor: "white", padding: "7px" }}
                src="https://akm-img-a-in.tosshub.com/lingo/uptak/resources/mangaldeep-logo-1.png"
              ></img>
              <img
                width="90px"
                height="60px"
                style={{ backgroundColor: "white", padding: "7px" }}
                src="https://akm-img-a-in.tosshub.com/lingo/uptak/resources/sensodent-k-1.png"
              ></img>
              <img
                width="90px"
                height="60px"
                style={{ backgroundColor: "white", padding: "7px" }}
                src="https://static.thelallantop.com/images/post/1717414469873_sunrise_logo_2nd_april_final.webp"
              ></img>
            </div>
          </div>
        </>
      )} */}
      {/* {size.width >= 1100 && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#D9368B",
              width: "85%",
              margin: "auto",
              gap: "68px",
              height: "80px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                // paddingTop: "1rem",
                gap: "8px",
                cursor: "pointer",
                backgroundColor: "rgb(217, 54, 139)",
                paddingLeft: "20px",
              }}
              onClick={() =>
                window.open("/election/lok-sabha-chunav-2024", "_self")
              }
            >
              <img
                src="/assets/images/chunav-name.png"
                width="197.56px"
                height="45.21px"
                style={{ alignSelf: "center" }}
              ></img>
            </div>
            <div
              class="divCategoriesSchedule snipcss-eqdpv"
              style={{
                margin: "0px 0px",
                backgroundColor: "rgb(217, 54, 139)",
                paddingLeft: "15px",
                alignContent: "center",
              }}
            >
              <a href="/election/lok-sabha-chunav-2024">
                <span>न्यूज़</span>
              </a>{" "}
              <a href="/election/lok-sabha-chunav-2024/results">
                <span>कौन जीता-कौन हारा</span>
              </a>{" "}
              <a href="/election/lok-sabha-chunav-2024/party-wise-result">
                <span>लोक सभा रिजल्ट</span>
              </a>
         
              <a href="/election/lok-sabha-chunav-2024/key-candidates">
                <span>प्रमुख उम्मीदवार</span>
              </a>
              
              <a href="/election/vidhan-sabha-chunav-2024/party-wise-result">
                <span>विधान सभा रिजल्ट</span>
              </a>
            
            </div>
          </div>
          <div
            style={{
              backgroundColor: "rgb(187 10 103)",
              width: "85%",
              margin: "auto",
            }}
          >
            <div
              style={{
                fontSize: "12px",
                fontWeight: "700",
                color: "white",
                paddingTop: "10px",
                textAlign: "center",
              }}
            >
              POWERED BY
            </div>
            <div
              style={{
                display: "flex",
                gap: "30px",
                justifyContent: "center",
                padding: "10px",
              }}
            >
              <img
                width="80px"
                height="60px"
                style={{ backgroundColor: "white", padding: "7px" }}
                src="https://akm-img-a-in.tosshub.com/lingo/uptak/resources/mangaldeep-logo-1.png"
              ></img>
              <img
                width="90px"
                height="60px"
                style={{ backgroundColor: "white", padding: "7px" }}
                src="https://akm-img-a-in.tosshub.com/lingo/uptak/resources/sensodent-k-1.png"
              ></img>
              <img
                width="90px"
                height="60px"
                style={{ backgroundColor: "white", padding: "7px" }}
                src="https://static.thelallantop.com/images/post/1717414469873_sunrise_logo_2nd_april_final.webp"
              ></img>
            </div>
          </div>
        </>
      )} */}
      {/* {size?.width < 600 && liveShowData?.totalCount > 0 && (
        <div className="live-mobile" style={{ marginTop: "20px" }}>
          <LiveShow />
        </div>
      )} */}
      {/* <ElectionsWidget /> */}
      {/* <div className="iframe-container">
        <div className="">
        <iframe className="responsive-iframe" scrolling="no"  src="https://electionresults.intoday.in/elections/2023/assembly-elections/html/december/exit-poll/exit-poll-tak.html?site=lallantop&v=2.1" width="100%" height="100%"></iframe>
        </div>
      </div> */}
      {/* <div
        className="checkMobileOrNot election-banner"
        onClick={() => router.push("https://www.thelallantop.com/election")}
        style={{
          minHeight: "90px",
          // marginTop: "5px",
          cursor: "pointer",
          // border: "1px solid black;",
        }}
      >
        <img
          // className="one-px-solid-br"
          style={{ minWidth: "100%" }}
          src={`https://static.thelallantop.com/images/post/1701653410443_774x172_copy_(1).webp?${
            !firstScroll ? "width=300" : ""
          }`}
          alt="adda-banner"
        />
      </div>
      <div className="iframe-container2">
        <div
          className="checkDesktopOrNot election-banner"
          onClick={() =>
            router.push("https://www.thelallantop.com/election")
          }
          style={{
            width: "88%",
            minHeight: "66px",
            marginBottom: "-10px",
            paddingTop: "10px",

            cursor: "pointer",
            // border: "1px solid black;",
          }}
        >
          <img
            // className="one-px-solid-br"
            style={{ minWidth: "100%" }}
            src={`https://static.thelallantop.com/images/post/1701653398038_1270x90_copy_(1).webp?${
              !firstScroll ? "width=300" : ""
            }`}
            alt="adda-banner"
          />
        </div>
        <div className="">
          <iframe
            className="responsive-iframe2"
            src="https://elections.mobiletak.com/tally/all?theme=llt"
            width="100%"
            height="450px"
          ></iframe>
        </div>
      </div> */}
      
      <MainContainer
        masterObj={masterObj}
        featuredPosts={featuredPosts}
        bannerData={bannerData}
        liveShowData={liveShowData}
        // cricketResponse={cricketResponse}
        quizPage={quizHomeData}
        firstScroll={firstScroll}
        newsData={cricketData}
      />

      {/* latest video secrtion starts */}
    
      {loadScrollableComponents &&  <LatestVideos homePageVideosData={homePageVideosData} router={router} language={language}/>}
   
      {/* latest video secrtion ends */}

      <div className="container whats">
        <a
          className="whatsappChannel"
          href="https://whatsapp.com/channel/0029Va9lDkJDp2QAHxZogJ2r"
          target="_blank"
          rel="noreferrer"
        >
          लल्लनटॉप का
          <span className="greenW">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="76.001"
              height="76"
              viewBox="0 0 76.001 76"
            >
              <g
                data-name="Group 50357"
                transform="translate(-203.999 -353.381)"
              >
                <path
                  id="Path_17532"
                  data-name="Path 17532"
                  d="M38.014,76h0a38.26,38.26,0,0,1-18.066-4.551L3.4,75.881A2.68,2.68,0,0,1,.108,72.594L4.546,56.04A38.258,38.258,0,0,1,.323,32.988,38.022,38.022,0,0,1,23.583,2.84,38,38,0,1,1,43.011,75.671,38.277,38.277,0,0,1,38.014,76ZM24.655,19.749a2.189,2.189,0,0,0-1.556.646,24.919,24.919,0,0,0,0,35.212,2.2,2.2,0,0,0,3.111,0,2.191,2.191,0,0,0,0-3.11,20.518,20.518,0,0,1,0-28.98,2.212,2.212,0,0,0,0-3.123A2.189,2.189,0,0,0,24.655,19.749Zm26.689,0a2.2,2.2,0,0,0-1.556,3.757,20.492,20.492,0,0,1,0,28.98,2.2,2.2,0,0,0,0,3.11,2.209,2.209,0,0,0,3.111,0,24.9,24.9,0,0,0,0-35.2A2.189,2.189,0,0,0,51.345,19.749ZM38.5,32a6,6,0,1,0,6,6A6.008,6.008,0,0,0,38.5,32Z"
                  transform="translate(204 353.381)"
                  fill="#58be55"
                ></path>{" "}
              </g>{" "}
            </svg>
          </span>
          चैनल
          <span class="waJoin">JOIN</span>
          करें
        </a>
      </div>
      {/* {
        <div style={{ display: "none" }}>
          <ListingPage></ListingPage>
        </div>
      } */}
      {loadScrollableComponents && (router.asPath.split("?")[0] === "/" ||
        router.asPath.split("?")[0] === "/video") && (
          <>
            {/* <ShortsSlider shortsData={shortsData} /> */}
            {size.width < 600 && router.asPath.split("?")[0] === "/" && (
              <GoogleAdContainer divId="div-gpt-ad-1701946028239-1" />
            )}
            {!router.pathname.includes("/text") &&
              !router.pathname.includes("/video") && (
                <VisualStories
                  responseVs={responseVs?.data?.all}
                  liveBlogWidgetData={liveBlogWidgetData}
                />
              )}
            {size.width < 600 && router.asPath.split("?")[0] === "/video" && (
              <GoogleAdContainer divId="div-gpt-ad-1701946028239-0" />
            )}
          </>
        )}
      {/* {router.pathname === "/" && (
        <GoogleAdContainer divId="div-gpt-ad-1686738608479-2" />
      )} */}
      {/* Top Shows Here */}
      {/* {(router.asPath === "/" || router.asPath === "/video") && (
        <TopShows topShowsData={topShowsData} />
      )} */}
      {/* {(router.asPath.split("?")[0] === "/" ||
        router.asPath.split("?")[0] === "/video") && (
        <TopShowsWidget TopShowsWidgetData={topShowsWidgetData} />
      )} */}
      {size.width > 600 &&
        !router.pathname.includes("/text") &&
        !router.pathname.includes("/video") && (
          <GoogleAdContainer divId="div-gpt-ad-1701945220410-1" />
        )}
      {size.width <= 499 && liveBlogWidgetData && liveBlogWidgetData?.length > 0 && 
        !router.pathname.includes("/text") &&
        !router.pathname.includes("/video") && (
          <BlogWidget liveBlogWidgetData={liveBlogWidgetData} />
        )}
      {size.width <= 499 && !router.pathname.includes("/video") && (
        <div className="container-row py-2" align="center">
          <p
            style={{ fontSize: "11px", color: "#b7b7b7", marginBottom: "0px" }}
          >
            Advertisement
          </p>
          <div
            id={
              size.width > 600
                ? router.asPath.split("?")[0] === "/"
                  ? "div-gpt-ad-1701945220410-2"
                  : router.asPath.split("?")[0] === "/text"
                    ? "div-gpt-ad-1701945220410-1"
                    : "div-gpt-ad-1701945220410-2"
                : router.pathname === "/"
                  ? "div-gpt-ad-1701946028239-2"
                  : router.asPath.split("?")[0] === "/text"
                    ? "div-gpt-ad-1701946028239-0"
                    : "div-gpt-ad-1701946028239-1"
            }
            style={{
              minWidth: "300px",
              minHeight: size.width > 600 ? "100px" : "260px",
              display: "block !important",
              alignContent: "center",
              textAlign: "-webkit-center",
            }}
          >
            <script
              type="text/javascript"
              dangerouslySetInnerHTML={{
                __html:
                  size.width > 600
                    ? router.asPath.split("?")[0] === "/"
                      ? `{googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701945220410-2')})`
                      : router.asPath.split("?")[0] === "/text"
                        ? `{googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701945220410-1')})`
                        : `{googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701946028239-1')})`
                    : router.pathname === "/"
                      ? `
              {googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701946028239-2')})
              `
                      : router.asPath.split("?")[0] === "/text"
                        ? `
              {googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701946028239-0')})
              `
                        : `
              {googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701946028239-1')})
              `,
              }}
            ></script>
          </div>
        </div>
      )}
      {loadScrollableComponents && <CategoryPosts
        shortsData={shortsData}
        NewsPageData={NewsPageData}
        topShowsWidgetData={topShowsWidgetData}
        topPodcastData={topPodcastData}
        authorWidgetData={authorWidgetData}
      />}

      {size.width < 600 && (
        <>
          <Container align="center">
            <div className="container-row py-2" align="center">
              <p
                style={{
                  fontSize: "11px",
                  color: "#b7b7b7",
                  textAlign: "center",
                  marginBottom: "0px",
                }}
              >
                Advertisement
              </p>
              <div
                id="div-gpt-ad-1701946028239-3"
                style={{
                  minWidth: "300px",
                  minHeight: "260px",
                  display: "block !important",
                  alignContent: "center",
                  textAlign: "-webkit-center",
                }}
                className="ads-auto-resize"
              >
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html: `{googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701946028239-3')})`,
                  }}
                ></script>
              </div>
            </div>
          </Container>
        </>
      )}
      {/* <GoogleAdContainer divId="div-gpt-ad-1686738608479-8" /> */}
      {/* <FAQComponent /> */}
      <TaboolaAdContainer />
      <script
        id="website-schema"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(breadcrumbSchema),
        }}
      />
      (
      <script
        id="website-schema-item"
        type="application/ld+json"
        // strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ItemList",
            itemListElement: ItemListSchema(),
          }),
        }}
      ></script>
      <script
        id="my-script-detail"
        type="application/ld+json"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "http://schema.org/",
            "@type": "WebPage",
            name: `${router.pathname === "/"
              ? "Hindi News, ताज़ा हिंदी समाचार | Breaking News Headlines Today in Hindi | Latest News Today Hindi | हिंदी न्यूज़ | The Lallantop"
              : router.pathname === "/text"
                ? "Hindi News Article, हिंदी न्यूज़ पढ़िए , Today Breaking News Article In Hindi, Today News Headlines"
                : router.pathname === "/video"
                  ? "Hindi News Video, Trending Video हिंदी न्यूज़ , Today Breaking News Video, Today News Video"
                  : "Hindi News Podcast, Trending podcast हिंदी न्यूज़ , Today Breaking News सुनिए, Today News Podcast"
              }`,
            description: `${router.pathname === "/"
              ? "Hindi News (हिंदी न्यूज़) - Read here देश, विदेश, खेल, राजनीति, क्राइम, ऑटोमोबाइल, टेक्नोलॉजी, शिक्षा, लाइफस्टाइल, क्रिकेट से सम्बंधित आज की मुख्य खबरें, ताज़ा हिंदी समाचार (Latest Hindi News), ब्रेकिंग न्यूज़ (Breaking News) और लेटेस्ट न्यूज़ (Latest News in Hindi) हिंदी में on The Lallantop."
              : router.pathname === "/text"
                ? "Hindi News Article (हिंदी न्यूज़) - पढ़िए देश, विदेश, खेल, राजनीति, क्राइम, ऑटोमोबाइल, टेक्नोलॉजी, शिक्षा, लाइफस्टाइल, क्रिकेट और ज्योतिष से सम्बंधित सभी समाचार हिंदी में (News Stories In Hindi) Lallantop पर.  Read Breaking News Headlines in Hindi on lallantop Website."
                : router.pathname === "/video"
                  ? "Hindi News Viral Video (हिंदी न्यूज़) - Watch देश, विदेश, खेल, राजनीति, क्राइम, ऑटोमोबाइल, टेक्नोलॉजी, शिक्षा, लाइफस्टाइल, क्रिकेट और ज्योतिष से सम्बंधित सभी समाचार हिंदी में (Video Stories In Hindi) Lallantop पर.  Watch Trending News Video in Hindi on lallantop Website."
                  : " Hindi News Viral Podcast (हिंदी न्यूज़) - listen देश, विदेश, खेल, राजनीति, क्राइम, ऑटोमोबाइल, टेक्नोलॉजी, शिक्षा, लाइफस्टाइल, क्रिकेट और ज्योतिष से सम्बंधित सभी समाचार हिंदी में (Podcast Stories In Hindi) Lallantop पर.  Watch Trending News PODCAST in Hindi on lallantop Website."
              }`,
            keywords: `${router.pathname === "/"
              ? "Hindi News, Hindi Samachar, Breaking News in Hindi, Today Headlines, Latest News in Hindi, हिंदी समाचार, हिंदी न्यूज़, ताजा ख़बरें"
              : router.pathname === "/text"
                ? "Hindi news Article, news story in hindi, breaking news article in hindi, latest news article in hindi, latest hindi news Stories, today news story in hindi, hindi news story today, हिंदी न्यूज़ पढ़िए, हिन्दी समाचार पढ़िए, पढ़िए हिंदी में समाचार"
                : router.pathname === "/video"
                  ? "Hindi news Video, Video story in hindi, breaking news Video in hindi, latest news Video in hindi, latest hindi news Video, watch today news story in hindi, hindi news today, हिंदी न्यूज़ देखिये, हिन्दी समाचार देखिये, देखिये हिंदी में समाचार, Trending Video, Viral Video today"
                  : "Hindi news Audio, audio story in hindi, breaking news podcast in hindi, latest news podcast in hindi, latest hindi news audio stories, Listen today news story in hindi, hindi news today, हिंदी न्यूज़ सुनिए, हिन्दी समाचार सुनिए, सुनिए हिंदी में समाचार, Trending audio stories, Viral podcast today"
              }`,
            url: `https://www.thelallantop.com${router.pathname}`,
            publisher: {
              "@type": "Organization",
              name: "The Lallantop",
              url: "https://www.thelallantop.com/",
              logo: {
                "@type": "ImageObject",
                url: "https://www.thelallantop.com/assets/images/lallatop-logo-m.svg",
                width: 600,
                height: 60,
              },
            },
          }),
        }}
      ></script>
      )
    </>
  );
};
export default HomePage;
